<script setup lang="ts">
import { ParagraphIntrotext } from '~/api/graphql/generated'

const props = defineProps<{
  entity?: ParagraphIntrotext
  text?: string
}>()

const text = computed(() => props.text || props.entity?.fieldText?.processed || '')
const { isOnLandingPage } = useChecks()
</script>

<template>
  <Section>
    <Introtext :text="text" :class="[{ 'text-large': isOnLandingPage }, 't-10 t-o-1 tl-o-0 ds-o-1 ds-8']" />
  </Section>
</template>
<style scoped lang="scss">
.section-introtext {
  @include fluid(margin-top, 30px, 64px);
}
</style>
